const eventhelper = {
  expand(row, state) {
    row["isPublished"] = state.publishedstates.includes(row.event_state.state);
    row["isOver"] = new Date(row.end_at * 1000) < new Date();
    row["shareURL"] = "/events/" + row.id;
    // TODO CHANGE THIS
    row["eventIcon"] = "";
    //row["eventIcon"] = require("@/assets/icons/icon-" +
    //  (row.type_of_event ? row.type_of_event : "other") +
    //  ".png");
    row["eventArtists"] =
      row.artists.length > 0
        ? row.artists.map((el) => el.name).join(", ")
        : " - ";
    row["eventLocation"] = row.location.name
      ? row.location.name + ", " + row.location.city
      : " - ";
    row["hasLocation"] = row.location.name != "";
    row["eventWebsite"] = row.website ? row.website : " - ";
    row["eventCrew"] = row.crew.name != "" ? "" + row.crew.name : " - ";
    row["mappedState"] = row.event_state.state;
    if (row.event_state.state == "requested") {
      row["mappedState"] =
        row.event_state.crew_confirmation == ""
          ? "requested_crew"
          : "requested_internal";
    }
  },
};
export default eventhelper;
