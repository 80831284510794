import Message from "@/store/messages";
import api from "@/store/api.js";
import asyncPagination from "@/store/asyncPagination";
import { required } from "vuelidate/lib/validators";
import { toQuery } from "../../mixins";

const deposits = {
  namespaced: true,
  modules: {
    pg: asyncPagination,
  },
  state: () => ({
    list: null,
    create: {
      deposit_units: [],
      crew_id: "",
      external: {
        organisation: "",
        asp: "",
        email: "",
        address: "",
        reciept: false,
        purpose: "",
      },
      has_external: false,
    },
    query: {},
    edit: {},
    current: null,
    update: null,
  }),
  mutations: {
    create(state, value) {
      state.create = value;
    },
    current(state, value) {
      state.current = value;
    },
    edit(state, value) {
      state.edit = value;
    },
    list(state, value) {
      state.list = value;
    },
    update(state, value) {
      state.update = value;
    },
    reset_create(state, value) {
      state.create = { deposit_units: [], crew_id: value };
    },
    reset_update(state) {
      state.update = null;
    },
    reset_current(state) {
      state.current = null;
    },
    reset_query(state) {
      state.query = {};
    },
    query(state, value) {
      state.query = value;
      if ("crew_id" in value) {
        if (!Array.isArray(value.crew_id)) {
          state.query.crew_id = [value.crew_id];
        }
      }
      if ("deposit_status" in value) {
        if (!Array.isArray(value.deposit_status)) {
          state.query.deposit_status = [value.deposit_status];
        }
      }
    },
    start_edit(state) {
      state.edit = JSON.parse(JSON.stringify(state.current));
    },
    reset_edit(state) {
      state.edit = {};
    },
  },
  getters: {
    query(state) {
      return state.query;
    },
    list(state) {
      return state.list;
    },
    edit(state) {
      return state.edit;
    },
    current(state) {
      return state.current;
    },
    validations() {
      let validation = {
        crew_id: {
          required,
        },
      };
      return {
        create: { ...validation },
        edit: { ...validation },
      };
    },
  },
  actions: {
    async add({ dispatch }) {
      await dispatch({ type: "create" });
      await dispatch({ type: "list" });
    },
    async update({ dispatch }) {
      await dispatch({ type: "updateReq" });
      await dispatch({ type: "list" });
    },
    sync({ commit, state }, data) {
      const depositId = data ? data.data : state.current.id;
      return new Promise((resolve, reject) => {
        api
          .get("/finances/deposit/sync/" + depositId)
          .then(() => {
            commit(
              "currentMsg",
              Message.getMessage("success", "deposit.synced"),
              {
                root: true,
              }
            );
            resolve();
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
    create({ commit, state }) {
      state.create.deposit_units.map((element) => {
        element.money.amount = parseInt(element.money.amount);
      });
      return new Promise((resolve, reject) => {
        api
          .post("/finances/deposit", state.create)
          .then((response) => {
            commit("current", response.data.payload);
            commit(
              "currentMsg",
              Message.getMessage("success", "deposit.created"),
              { root: true }
            );
            resolve();
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
    updateReq({ commit, state }) {
      state.edit.deposit_units.map((element) => {
        element.money.amount = parseInt(element.money.amount);
      });
      return new Promise((resolve, reject) => {
        api
          .put("/finances/deposit", state.edit)
          .then((response) => {
            commit("current", response.data.payload);
            commit(
              "currentMsg",
              Message.getMessage("success", "deposit.updated"),
              { root: true }
            );
            resolve();
          })
          .catch((error) => {
            if (error.response.data.message === "deposit_confirmed_failure") {
              commit(
                "currentMsg",
                Message.getMessage("error", "error.deposit_confirmed_failure"),
                { root: true }
              );
            } else {
              commit(
                "currentMsg",
                Message.getMessage("error", "error.unknown"),
                {
                  root: true,
                }
              );
            }
            reject(error);
          });
      });
    },
    list({ commit, state }) {
      var obj = state.query;
      const query = toQuery(obj);
      return new Promise((resolve, reject) => {
        api
          .get("/finances/deposit" + query)
          .then((response) => {
            commit(
              "pg/pageSize",
              response.data.payload === null ? 1 : response.data.payload.length
            );
            commit(
              "pg/listLength",
              response.data.payload === null ? 1 : response.data.payload.length
            );
            commit("list", response.data.payload);
            resolve();
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
    getByID({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .get("/finances/deposit/" + data.data)
          .then((response) => {
            commit("current", response.data.payload), resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    uploadReceipt({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .post("/finances/receipt", data.data, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            if (response.status == 201) {
              resolve();
            }
            resolve();
          })
          .catch((error) => {
            if (error.response.status == 400) {
              commit(
                "currentMsg",
                Message.getMessage("error", "error.permission"),
                { root: true }
              );
            } else if (error.response.status == 413) {
              commit(
                "currentMsg",
                Message.getMessage("error", "error.too_big"),
                { root: true }
              );
            } else {
              commit(
                "currentMsg",
                Message.getMessage("error", "error.unknown"),
                { root: true }
              );
            }
            reject(error);
          });
      });
    },
    deleteReceipt({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .delete("/finances/receipt/" + data.data)
          .then((response) => {
            //commit("current", response.data.payload);
            resolve(response.data.payload);
          })
          .catch((error) => {
            if (error.response.status == 400) {
              commit(
                "currentMsg",
                Message.getMessage("error", "error.permissions"),
                { root: true }
              );
            } else {
              commit(
                "currentMsg",
                Message.getMessage("error", "error.unknown"),
                { root: true }
              );
            }
            reject(error);
          });
      });
    },
  },
};
export default deposits;
