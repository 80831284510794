<template>
  <div class="top-nav">
    <ul class="navigation">
      <li class="navigation-link">
        <a href="/" class="navigation-logo">
          <img src="~@/assets/img/vca_logo_plain.png" />
        </a>
      </li>
      <li :key="route.name" v-for="route in navigationList">
        <router-link :title="$t(route.meta.title)" :to="route.path">{{
          $t(route.meta.title)
        }}</router-link>
      </li>
      <!-- use the router-link component for navigation. -->
      <!-- specify the link by passing the `to` prop. -->
      <!-- `<router-link>` will render an `<a>` tag with the correct `href` attribute -->
    </ul>
    <div>
      <ul class="navigation-breadcrumbs">
        <li :key="route.name" v-for="(route, index) in navigationPositionList">
          <router-link :title="$t(route.meta.title)" :to="route.path">{{
            $t(route.meta.title)
          }}</router-link>
          <span v-if="index < navigationPositionList.length - 1"> >> </span>
        </li>
        <li
          v-if="breadCrumb"
          id="navigation-breadcrumb-end"
          v-html="breadCrumb"
        ></li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "TopNavigation",
  computed: {
    ...mapGetters({
      session: "session",
      breadCrumb: "breadCrumb",
      hasCrew: "user/crew/hasCrew",
      currentUser: "user/current",
      pool_roles: "user/roles/pool",
      hasPoolPermission: "user/roles/hasPoolPermission",
      hasSystemPermission: "user/roles/hasSystemPermission",
    }),
    navigationPositionList() {
      return this.$route.matched;
    },
    navigationList() {
      return this.$router.options.routes.filter((route) => {
        // Check for correct meta
        if (route.meta == undefined) {
          route.meta = { title: "Missing identifier" };
          return true;
        }

        // Check visibility
        if (route.meta.visible != undefined && !route.meta.visible) {
          return false;
        }

        // Check crew
        if (route.meta.crew != undefined && route.meta.crew) {
          if (!this.session) {
            return false;
          }
          if (!this.hasCrew) {
            return false;
          }
        }

        // Check permissions
        if (route.meta.permissions != undefined) {
          if (!this.session) {
            return false;
          }

          if (this.hasSystemPermission()) {
            var sysroles = this.currentUser.system_roles.find(
              (el) =>
                el.name == "admin" ||
                route.meta.permissions.find((p) => el.name == p) != undefined
            );
            if (sysroles != undefined) {
              return true;
            }
          }

          if (this.hasPoolPermission()) {
            var roles = this.pool_roles.find(
              (el) =>
                route.meta.permissions.find((p) => el.name == p) != undefined
            );
            if (roles != undefined) {
              return true;
            }
          }
          return false;
        }

        // Check session
        if (route.meta.session != undefined) {
          if (
            (this.session && route.meta.session == false) ||
            (!this.session && route.meta.session == true)
          ) {
            return false;
          }
        }

        return true;
      });
    },
  },
};
</script>
<style lang="scss">
.top-nav {
  position: fixed;
  width: 100%;
  z-index: 100;
  height: 100px;
  background-color: $blue;
  ul.navigation {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;

    li {
      &.navigation-link {
        padding-top: 8px;
        margin-left: 8px;
        a.navigation-logo {
          padding: 0px;
          img {
            margin: auto;
            display: block;
            width: 100px;
          }
        }
      }

      float: left;
      padding: 5px;
      a {
        text-transform: uppercase;
        display: block;
        color: white;
        text-align: center;
        padding: 14px 16px;
        text-decoration: none;
        border: solid thin transparent;
        /* Change the link color to #111 (black) on hover */

        &.router-link-active {
          background-color: $vca-primary-dark;
        }
        &:hover:not(.router-link-active) {
          color: $vca-primary-dark;
        }
        &:hover {
          color: white;
        }
      }
    }
  }

  ul.navigation-breadcrumbs {
    margin: 0;
    background-color: white;
    overflow: hidden;
    list-style-type: none;
    li {
      padding: 0 10px;
      float: left;
      span {
        padding-left: 15px;
      }
      a {
        color: $blue;
        text-decoration: none;
        &:hover {
          color: $vca-primary-dark !important;
        }
      }
    }
  }
}
</style>
