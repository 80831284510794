import api from "@/store/api.js";
import asyncPagination from "@/store/asyncPagination";
import Message from "@/store/messages";
import eventhelper from "./eventhelper.js";
import participations from "./participations.js";
import tour from "./tour.js";
//import event_list from './eventlist.json'
import { required, requiredIf } from "vuelidate/lib/validators";
const defaultApplicationStart = () => {
  const today = new Date();
  const date = new Date(today);
  date.setDate(today.getDate() + 3 * 7);
  date.setHours(0, 0, 0, 0);
  return parseInt(date.getTime() / 1000);
};
const defaultApplicationEnd = () => {
  const today = new Date();
  const date = new Date(today);
  date.setDate(today.getDate() + 5 * 7);
  date.setHours(23, 59, 0, 0);
  return parseInt(date.getTime() / 1000);
};
const event = {
  namespaced: true,
  modules: {
    pg: asyncPagination,
    tour: tour,
    participations: participations,
  },
  state: () => ({
    list: null,
    eventstates: [
      "created",
      "requested_crew",
      "requested_internal",
      "published",
      "finished",
      "closed",
      "canceled",
    ],
    publishedstates: ["published", "finished", "closed"],
    eventtypes: [
      {
        title: "events.eventtypes.concert.title",
        subtitle: "events.eventtypes.concert.subtitle",
        label: "events.eventtypes.concert.label",
        value: "concert",
      },
      {
        title: "events.eventtypes.festival.title",
        subtitle: "events.eventtypes.festival.subtitle",
        label: "events.eventtypes.festival.label",
        value: "festival",
      },
      //{title: 'events.eventtypes.tour.title', subtitle: 'events.eventtypes.tour.subtitle', label: 'events.eventtypes.tour.label',value:'tour'},
      //{title: 'events.eventtypes.goldeimer.title', subtitle: 'events.eventtypes.goldeimer.subtitle', label: 'events.eventtypes.goldeimer.label',value:'goldeimer'},
      {
        title: "events.eventtypes.stadium.title",
        subtitle: "events.eventtypes.stadium.subtitle",
        label: "events.eventtypes.stadium.label",
        value: "stadium",
      },
      {
        title: "events.eventtypes.network.title",
        subtitle: "events.eventtypes.network.subtitle",
        label: "events.eventtypes.network.label",
        value: "network",
      },
      //{title: 'events.eventtypes.nwgathering.title', subtitle: 'events.eventtypes.nwgathering.subtitle', label: 'events.eventtypes.nwgathering.label',value:'nwgathering'},
      //{title: 'events.eventtypes.regiocamp.title', subtitle: 'events.eventtypes.regiocamp.subtitle', label: 'events.eventtypes.regiocamp.label',value:'regiocamp'},
      {
        title: "events.eventtypes.other.title",
        subtitle: "events.eventtypes.other.subtitle",
        label: "events.eventtypes.other.label",
        value: "other",
      },
    ],
    dropdown: [],
    create: {
      name: "",
      type_of_event: "concert",
      additional_information: "",
      website: "",
      tour_id: "",
      location: {
        name: "",
        street: "",
        city: "",
        country: "",
        country_code: "",
        number: "",
        position: {
          lat: 0,
          lng: 0,
        },
        place_id: "",
        sublocality: "",
      },
      meeting_url: "",
      artists: [],
      artist_ids: [],
      organizer_id: "",
      organizer: {
        id: "",
        name: "",
        type_of_organizer: "",
      },
      start_at: parseInt((new Date().getTime() + 6048e5 * 6) / 1000),
      end_at: parseInt((new Date().getTime() + 6048e5 * 6 + 216e5) / 1000),
      crew_id: "",
      crew: {
        id: "",
        name: "",
        email: "",
      },
      event_asp_id: "",
      event_asp: {
        user_id: "",
        full_name: "",
        display_name: "",
        email: "",
        phone: "",
      },
      internal_asp_id: "",
      internal_asp: {
        user_id: "",
        full_name: "",
        display_name: "",
        email: "",
        phone: "",
      },
      external_asp: {
        full_name: "",
        display_name: "",
        email: "",
        phone: "",
      },
      application: {
        start_date: defaultApplicationStart(),
        end_date: defaultApplicationEnd(),
        supporter_count: 1,
      },
      event_tools: {
        tools: [],
        special: "",
      },
      creator_id: "",
      event_state: {
        state: "created",
        crew_confirmation: "",
        internal_confirmation: "",
      },
    },
    create_origin: {
      name: "",
      type_of_event: "concert",
      additional_information: "",
      website: "",
      tour_id: "",
      location: {
        name: "",
        street: "",
        city: "",
        country: "",
        country_code: "",
        number: "",
        position: {
          lat: 0,
          lng: 0,
        },
        place_id: "",
        sublocality: "",
      },
      meeting_url: "",
      artists: [],
      artist_ids: [],
      organizer_id: "",
      organizer: {
        id: "",
        name: "",
        type_of_organizer: "",
      },
      start_at: parseInt((new Date().getTime() + 6048e5 * 6) / 1000),
      end_at: parseInt((new Date().getTime() + 6048e5 * 6 + 216e5) / 1000),
      crew_id: "",
      crew: {
        id: "",
        name: "",
        email: "",
      },
      event_asp_id: "",
      event_asp: {
        user_id: "",
        full_name: "",
        display_name: "",
        email: "",
        phone: "",
      },
      internal_asp_id: "",
      internal_asp: {
        user_id: "",
        full_name: "",
        display_name: "",
        email: "",
        phone: "",
      },
      external_asp: {
        full_name: "",
        display_name: "",
        email: "",
        phone: "",
      },
      application: {
        start_date: defaultApplicationStart(),
        end_date: defaultApplicationEnd(),
        supporter_count: 1,
      },
      event_tools: {
        tools: [],
        special: "",
      },
      creator_id: "",
      event_state: {
        state: "created",
        crew_confirmation: "",
        internal_confirmation: "",
      },
    },
    current: null,
  }),
  mutations: {
    create(state, value) {
      state.create = value;
    },
    copy(state, value) {
      state.create = { ...value };
      delete state.create.id;
      state.create.event_state = state.create_origin.event_state;
      state.create.creator_id = state.create_origin.creator_id;
      state.create.tour_id = state.create_origin.tour_id;
      state.create.internal_asp = state.create_origin.internal_asp;
      state.create.internal_asp_id = state.create_origin.internal_asp_id;
      state.create.event_asp = state.create_origin.event_asp;
      state.create.event_asp_id = state.create_origin.event_asp_id;
      state.create.external_asp = state.create_origin.external_asp;
    },
    isCopy(state, value) {
      state.copy = value;
    },
    resetCreate(state) {
      state.create = { ...state.create_origin };
    },
    current(state, value) {
      state.current = value;
      if (value && value.event_state) {
        eventhelper.expand(state.current, state);
      }
    },
    list(state, value) {
      value
        ? value.forEach((row) => {
            eventhelper.expand(row, state);
          })
        : null;
      state.list = value;
    },
    dropdown(state, value) {
      //var selectList = []
      value
        ? value.forEach((row) => {
            row["title"] = row["name"];
            row["label"] = row["name"];
            row["subtitle"] = row["type_of_event"];
            row["value"] = row["id"];
          })
        : null;
      state.dropdown = value;
    },
  },
  getters: {
    isCopy(state) {
      return state.copy;
    },
    eventtypes(state) {
      return state.eventtypes;
    },
    publishedstates(state) {
      return state.publishedstates;
    },
    eventstates(state) {
      return state.eventstates;
    },
    getEvent: (state) => (id) => {
      return state.list ? state.list.find((el) => el.id == id) : {};
    },
    validations(state, getters, rootState) {
      let validation = {
        type_of_event: { required },
        /*crew: {
                    id: { required }
                },*/
        name: { required },
        application: {
          start_date: {
            required,
            maxValue(val, { end_date }) {
              return new Date(val) < new Date(end_date);
            },
          },
          end_date: {
            required,
            minValue(val, { start_date }) {
              return new Date(val) > new Date(start_date);
            },
          },
        },
        start_at: {
          required,
          minValue(val, { end_at }) {
            return new Date(val) < new Date(end_at);
          },
        },
        end_at: {
          required,
          minValue(val, { start_at }) {
            return new Date(val) > new Date(start_at);
          },
        },
        /*external_asp: {
                    full_name: { required },
                    email: { required, email }
                },
                internal_asp: {
                    user_id: { required },
                    display_name: { required },
                    full_name: { required },
                    email: { required, email },
                    phone: { required }
                }*/
      };

      let createVal = {
        type_of_event: {
          required,
        },
        event_asp: {
          id: {
            required: requiredIf(function () {
              return (
                state.create.crew &&
                state.create.crew_id &&
                new Date() < new Date(state.create.end_at * 1000)
              );
            }),
          },
        },
        meeting_url: {
          required: requiredIf(function () {
            return state.create.location.name == "";
          }),
        },
        location: {
          name: {
            required: requiredIf(function () {
              return state.create.meeting_url == "";
            }),
          },
        },
      };
      let currentVal = {
        type_of_event: {
          required,
        },
        event_asp: {
          id: {
            required: requiredIf(function () {
              return (
                state.current.crew &&
                state.current.crew_id &&
                new Date() < new Date(state.current.end_at * 1000)
              );
            }),
          },
        },
        meeting_url: {
          required: requiredIf(function () {
            return (
              state.current &&
              state.current.location &&
              state.current.location.name == ""
            );
          }),
        },
        location: {
          name: {
            required: requiredIf(function () {
              return state.current && state.current.meeting_url == "";
            }),
          },
        },
        internal_asp: {
          id: {
            required: requiredIf(function () {
              return (
                state.current.crew &&
                state.current.crew.id &&
                rootState.user.roles.system.length > 1
              );
            }),
          },
        },
      };

      let valueVal = state.current == null ? createVal : currentVal;

      return {
        create: { ...validation, ...createVal },
        value: { ...validation, ...valueVal },
        current: { ...validation, ...currentVal },
      };
    },
    list(state) {
      return state.list;
    },
    eventList(state) {
      return state.dropdown;
    },
    current(state) {
      return state.current;
    },
    create(state) {
      return state.create;
    },
    maxContingent(state) {
      if (["concert", "tour", "stadium"].includes(state.create.type_of_event)) {
        return 50;
      } else if (
        ["regiocamp", "festival", "goldeimer"].includes(
          state.create.type_of_event
        )
      ) {
        return 300;
      } else if (
        ["nwgathering", "other"].includes(state.create.type_of_event)
      ) {
        return 2000;
      }
      return 10;
    },
    isCreateTourType(state) {
      return state.create.type_of_event == "tour";
    },
    organizerTypes() {
      return ["concert", "tour", "festival"];
    },
    artistTypes() {
      return ["concert", "tour", "stadium", "festival"];
    },
  },
  actions: {
    async create({ dispatch }) {
      await dispatch({ type: "createReq" });
      await dispatch({ type: "list" });
    },
    async update({ dispatch }) {
      await dispatch({ type: "updateReq" });
      await dispatch({ type: "list" });
    },
    sync({ commit, state }, data) {
      const eventId = data ? data.data : state.current.id;
      return new Promise((resolve, reject) => {
        api
          .get("/events/event/sync/" + eventId)
          .then(() => {
            commit(
              "currentMsg",
              Message.getMessage("success", "event.synced"),
              {
                root: true,
              }
            );
            resolve();
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
    createReq({ commit, state }) {
      return new Promise((resolve, reject) => {
        api
          .post("/events/event", state.create)
          .then((response) => {
            commit("current", response.data.payload);
            commit(
              "currentMsg",
              Message.getMessage("success", "event.created"),
              { root: true }
            );
            resolve(response.data.payload);
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
    updateReq({ commit, state }) {
      return new Promise((resolve, reject) => {
        api
          .put("/events/event", state.current)
          .then((response) => {
            commit("current", response.data.payload);
            commit(
              "currentMsg",
              Message.getMessage("success", "event.updated"),
              { root: true }
            );
            resolve();
          })
          .catch((error) => {
            if (error.response.data.message == "taking_failure") {
              commit(
                "currentMsg",
                Message.getMessage("error", "error.taking_failure_event"),
                {
                  root: true,
                }
              );
            } else {
              commit(
                "currentMsg",
                Message.getMessage("error", "error.unknown"),
                {
                  root: true,
                }
              );
            }
            reject(error);
          });
      });
    },
    get({ commit, state }) {
      return new Promise((resolve, reject) => {
        api
          .get("/events/event/" + state.current.id)
          .then((response) => {
            commit("current", response.data.payload);
            resolve();
          })
          .catch((error) => {
            if (error.response.status != 404) {
              commit(
                "currentMsg",
                Message.getMessage("error", "error.unknown"),
                {
                  root: true,
                }
              );
            }
            reject(error);
          });
      });
    },
    getView({ commit, state }) {
      return new Promise((resolve, reject) => {
        api
          .get("/events/event/view/" + state.current.id)
          .then((response) => {
            commit("current", response.data.payload);
            resolve();
          })
          .catch((error) => {
            if (error.response.status != 404) {
              commit(
                "currentMsg",
                Message.getMessage("error", "error.unknown"),
                {
                  root: true,
                }
              );
            }
            reject(error);
          });
      });
    },
    getDetails({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .get("/events/event/details/" + data.data)
          .then((response) => {
            resolve(response.data.payload);
          })
          .catch((error) => {
            if (error.response.status != 404) {
              commit(
                "currentMsg",
                Message.getMessage("error", "error.unknown"),
                {
                  root: true,
                }
              );
            }
            reject(error);
          });
      });
    },
    email({ commit }) {
      return new Promise((resolve, reject) => {
        api
          .get("/events/event/email")
          .then((response) => {
            commit("list", response.data.payload);
            commit("dropdown", response.data.payload);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    list({ commit }) {
      return new Promise((resolve, reject) => {
        api
          .get("/events/event")
          .then((response) => {
            commit("list", response.data.payload);
            commit("dropdown", response.data.payload);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    listAsp() {
      return new Promise((resolve, reject) => {
        api
          .get("/events/event/user")
          .then((response) => {
            resolve(response.data.payload);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    delete({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .delete("/events/event/" + data.data)
          .then((respnse) => {
            commit(
              "currentMsg",
              Message.getMessage("success", "event.deleted"),
              { root: true }
            );
            resolve(respnse.data.payload);
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
    public({ commit }) {
      return new Promise((resolve, reject) => {
        api
          .get("/events/event/public")
          .then((response) => {
            commit("list", response.data.payload);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    queryList(_, data) {
      var params = Object.keys(data.data)
        .map(function (k) {
          return encodeURIComponent(k) + "=" + encodeURIComponent(data.data[k]);
        })
        .join("&");

      return new Promise((resolve, reject) => {
        api
          .get("/events/event?" + params)
          .then((response) => {
            resolve(response.data.payload);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
export default event;
